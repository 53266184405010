import React from "react";
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const LearningActivitiesHoursCompletedTable = ({ chartData }) => {
  // console.log(
  //   "LearningActivitiesHoursCompletedTable",
  //   chartData.data.totalLearningActivity.hours
  // );
  const hours = chartData.data.totalLearningActivity.hours;
  const minutes = chartData.data.totalLearningActivity.minutes;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {/* First Row */}
          <TableRow sx={{ backgroundColor: "rgba(165, 154, 154, 0.438)" }}>
            <TableCell align="center">Classroom training</TableCell>
            <TableCell align="center">Training at work</TableCell>
            <TableCell align="center">Learning Support</TableCell>
            <TableCell align="center">Lectures</TableCell>
          </TableRow>
          <TableRow>
            {hours.slice(0, 4).map((hour, index) => (
              <TableCell key={index} align="center">
                {hour}:{minutes[index]} {/* No need to offset minutes */}
              </TableCell>
            ))}
          </TableRow>

          {/* Second Row */}
          <TableRow sx={{ backgroundColor: "rgba(165, 154, 154, 0.438)" }}>
            <TableCell align="center">Online learning</TableCell>
            <TableCell align="center">Shadowing</TableCell>
            <TableCell align="center">Mentoring</TableCell>
            <TableCell align="center">Writing Assignments</TableCell>
          </TableRow>
          <TableRow>
            {hours.slice(4, 8).map((hour, index) => (
              <TableCell key={index} align="center">
                {hour}:{minutes[index + 4]} {/* Properly offset minutes */}
              </TableCell>
            ))}
          </TableRow>

          {/* Third Row */}
          <TableRow sx={{ backgroundColor: "rgba(165, 154, 154, 0.438)" }}>
            <TableCell align="center">Theory Learning</TableCell>
            <TableCell align="center">Manufacturer Training</TableCell>
            <TableCell align="center">Role Playing</TableCell>
            <TableCell align="center">Simulation Exercises</TableCell>
          </TableRow>
          <TableRow>
            {hours.slice(8, 12).map((hour, index) => (
              <TableCell key={index} align="center">
                {hour}:{minutes[index + 8]} {/* Properly offset minutes */}
              </TableCell>
            ))}
          </TableRow>

          {/* Fourth Row */}
          <TableRow sx={{ backgroundColor: "rgba(165, 154, 154, 0.438)" }}>
            <TableCell align="center">Industry Visits</TableCell>
            <TableCell align="center">Participation in Competitions</TableCell>
            <TableCell align="center">Revision</TableCell>
            <TableCell align="center">Other Otj Learning Activity</TableCell>
          </TableRow>
          <TableRow>
            {hours.slice(12, 16).map((hour, index) => (
              <TableCell key={index} align="center">
                {hour}:{minutes[index + 12]} {/* Properly offset minutes */}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LearningActivitiesHoursCompletedTable;
