import moment from "moment";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const formatMinutesInOOOO = (minutes) => {
  let prefix = Math.floor(minutes / 60);
  let sufix = Math.floor(minutes % 60);
  return (
    (prefix < 10 ? "0" + prefix : prefix) +
    ":" +
    (sufix < 10 ? "0" + sufix : sufix)
  );
};

export const formatSecondsInOOOO = (seconds) => {
  if (!seconds || isNaN(seconds) || seconds < 0) return "00:00"; // Ensure no invalid values
  let minutes = seconds / 60;
  let prefix = Math.floor(minutes / 60);
  let suffix = Math.floor(minutes % 60);
  return (
    (prefix < 10 ? "0" + prefix : prefix) +
    ":" +
    (suffix < 10 ? "0" + suffix : suffix)
  );
};

export const getHours = (seconds) => {
  seconds = Math.abs(seconds);
  var hours = Math.abs(Math.floor(seconds / 3600));
  var minutes = Math.abs((seconds % 3600) / 60);
  let roundedMinutes = Math.abs(Math.round(minutes));
  return {
    hours: hours < 10 ? "0" + hours : `${hours}`,
    minutes: roundedMinutes < 10 ? "0" + roundedMinutes : `${roundedMinutes}`,
  };
};

export const getPlanHoursMinutes = (minutes) => {
  const duration = moment.duration(minutes, "minutes");

  const h = duration.hours(); // 1
  const m = duration.minutes(); // 20
  const s = duration.seconds(); // 25
  // console.log(s)
  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
};

export const getWeeksBetweenTwoDates = (start, end) => {
  let a = moment(start, "DD-MM-YYYY");
  let b = moment(end, "DD-MM-YYYY");
  return b.diff(a, "week");
};

export const getDaysBetweenTwoDates = (start, end) => {
  let a = moment(start, "DD-MM-YYYY");
  let b = moment(end, "DD-MM-YYYY");
  return b.diff(a, "day");
};

export const getDateFromUkString = (ukStringdate) => {
  let date = moment(ukStringdate, "DD/MM/YYYY").toDate();
  return date;
};

export const getProgramStatusColor = {
  On: "#FACE6B",
  Off: "#BABBBC",
  Completed: "#7CBD1A",
  Break: "#3ACFF8",
};

export const getTargetStatusColor = {
  On: "#7CBD1A",
  Below: "#BD1A41",
};

export const getChangeAbleIndex = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    if (!arr[i].isChanged) {
      return i;
      // console.log(i);
    }
  }
};

// To calculate the no. of days between two dates
export const daysBetween = (first, second) => {
  // Copy date parts of the timestamps, discarding the time parts.
  var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
  var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

  // Do the math.
  var millisecondsPerDay = 1000 * 60 * 60 * 24;
  var millisBetween = two.getTime() - one.getTime();
  var days = millisBetween / millisecondsPerDay;

  // Round down.
  return Math.floor(days);
};
