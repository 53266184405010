import React, { useEffect, useState, Fragment, useContext } from "react";
import "./LearnerTrainingModal.css";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Modal,
  Button,
  Box,
  Typography,
  Drawer,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  formatMinutesInOOOO,
  formatSecondsInOOOO,
} from "../../utils/functions";
import { cloneDeep } from "lodash";
import { AppContext } from "../../context/appContext";
import axios from "axios";
import jwtDecode from "jwt-decode";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "lightgray",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
  },
  button: {
    background: "#5335CA",
    color: "white",
    "&:hover": {
      background: "#5335CA",
    },
  },
  icons: {
    cursor: "pointer",
  },
  indicator: {
    backgroundColor: "#5335ca",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100vw",
  maxHeight: 550,
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  borderRadius: 2,
  p: 4,
};

export default function LearnerTrainingModal(props) {
  const classes = useStyles();
  const [learnerFaculty, setLearnerFaculty] = useState({
    coach: "",
    mentor: "",
    trainer: "",
  });

  const [value, setValue] = useState(1);
  const [changedSeconds, setChangedSeconds] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [newLearner, setNewLearner] = useState();
  const { setData, data, getUserData, handleToggle, updateMessage } =
    useContext(AppContext);

  // console.log("data::::::", data);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (props.userData) {
      const learner = cloneDeep(props.userData);
      learner.monthlyHourMinutesUpdated = learner.monthlyHourMinutes;
      setNewLearner(learner);
    }
  }, [props.userData]);

  // To Calculate The Changed Durations Of New Learner
  useEffect(() => {
    if (
      newLearner &&
      newLearner.monthlyHourMinutesUpdated &&
      newLearner.monthlyHourMinutesUpdated.length > 0
    ) {
      const changedHours = newLearner.monthlyHourMinutesUpdated.map((x) =>
        x.targetHours ? parseInt(x.targetHours, 10) : 0
      );
      const changedMinutes = newLearner.monthlyHourMinutesUpdated.map((x) =>
        x.targetMinutes ? parseInt(x.targetMinutes, 10) : 0
      );

      const totalHours = changedHours.reduce((a, b) => a + b, 0);
      const totalMinutes = changedMinutes.reduce((a, b) => a + b, 0);

      const calculatedSeconds = Math.floor(
        totalHours * 3600 + totalMinutes * 60
      );

      setChangedSeconds(calculatedSeconds);
    }
  }, [newLearner]);
  // eslint-disable-line react-hooks/exhaustive-deps

  // const handleDurationChange = (e, index) => {
  //   let { name, value } = e.target;
  //   let cloneDurations = cloneDeep(newLearner.monthlyHourMinutesUpdated);

  //   if (name === "hours") {
  //     if (value >= 0) {
  //       cloneDurations[index].targetHours = Number(value);
  //     } else {
  //       updateMessage("Hours can not be less than 0");
  //       return;
  //     }
  //   } else {
  //     if (value >= 0 && value < 60) {
  //       cloneDurations[index].targetMinutes = Number(value);
  //     } else {
  //       updateMessage("Minutes can not be greater than 59 or less than 0");
  //       return;
  //     }
  //   }

  //   // console.log(cloneDurations);
  //   setNewLearner({
  //     ...newLearner,
  //     monthlyHourMinutesUpdated: cloneDurations,
  //   });

  // };

  // const handleDurationChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const updatedMonthlyHourMinutes = [...monthlyHourMinutes];
  //   const updatedDuration = { ...updatedMonthlyHourMinutes[index] };
  //   updatedDuration[name] = parseInt(value, 10);
  //   updatedMonthlyHourMinutes[index] = updatedDuration;
  //   setMonthlyHourMinutes(updatedMonthlyHourMinutes);
  // };
  // FormHandler For Reset Learner Durations Modal
  // console.log("newLearner", newLearner);

  const handleDurationChangeHours = (e, index) => {
    let { name, value } = e.target;
    let cloneDurations = cloneDeep(newLearner.monthlyHourMinutesUpdated);

    if (name === "hours") {
      // Check if the value is an empty string or a valid number
      if (value === "" || /^\d+$/.test(value)) {
        // Allow empty input or valid numeric values
        cloneDurations[index].targetHours =
          value === "" ? "" : parseInt(value, 10);
      } else if (/^\d+$/.test(value) && parseInt(value, 10) >= 0) {
        // Only convert to number and assign if it's a valid positive integer
        cloneDurations[index].targetHours = parseInt(value, 10);
      } else {
        updateMessage("Hours must be a positive number or empty.");
        return;
      }
    } else {
      // Assuming this else branch is for "minutes" or similar
      if (
        /^\d+$/.test(value) &&
        parseInt(value, 10) >= 0 &&
        parseInt(value, 10) < 60
      ) {
        cloneDurations[index].targetMinutes = parseInt(value, 10);
      } else {
        updateMessage("Minutes must be a number ranging  between 0 and 59.");
        return;
      }
    }

    setNewLearner({
      ...newLearner,
      monthlyHourMinutesUpdated: cloneDurations,
    });
  };

  const handleDurationChangeMinutes = (e, index) => {
    let { name, value } = e.target;
    let cloneDurations = cloneDeep(newLearner.monthlyHourMinutesUpdated);

    if (name === "minutes") {
      // Allow empty string for the input
      if (value === "" || (/^\d+$/.test(value) && parseInt(value, 10) < 60)) {
        // Allow empty input or valid numeric values within the range
        cloneDurations[index].targetMinutes =
          value === "" ? "" : parseInt(value, 10);
      } else if (
        /^\d+$/.test(value) &&
        parseInt(value, 10) >= 0 &&
        parseInt(value, 10) < 60
      ) {
        cloneDurations[index].targetMinutes = parseInt(value, 10);
      } else {
        updateMessage("Minutes must be between 0 and 59.");
        return;
      }
    } else if (name === "hours") {
      if (value === "") {
        cloneDurations[index].targetHours = "";
      } else if (/^\d+$/.test(value) && parseInt(value, 10) >= 0) {
        cloneDurations[index].targetHours = parseInt(value, 10);
      } else {
        updateMessage("Hours must be a positive number or empty.");
        return;
      }
    }

    setNewLearner({
      ...newLearner,
      monthlyHourMinutesUpdated: cloneDurations,
    });
  };

  const getTimeDifference = () => {
    let seconds = Number(changedSeconds) - Number(newLearner?.totalSeconds);
    seconds = Math.abs(seconds);
    // console.log(seconds);
    return formatSecondsInOOOO(seconds);
  };
  // console.log("changedSeconds", changedSeconds);
  // console.log("newLearner.totalSeconds", newLearner.totalSeconds);

  const calculateLearnerHoursHandler = async () => {
    if (changedSeconds < newLearner?.plannedTotalSeconds) {
      console.log("Condition met: Showing warning message.");
      updateMessage(
        "The Forecast Hours should be greater than or equal to the Planned Off-The-Job Hours!"
      );
    } else {
      console.log("Condition not met: Proceeding with API call.");
      handleToggle(true);

      let body = {
        monthlyHourMinutes: newLearner.monthlyHourMinutesUpdated,
        totalSeconds: changedSeconds,
        updateProgress: true,
      };
      console.log("BODY:", body);
      try {
        let formData = new FormData();
        formData.append("data", JSON.stringify(body));

        await axios
          .put("learner", formData, {
            config: { handlerEnabled: true },
          })
          .then((response) => {
            handleToggle(false);
            updateMessage("The weekly OTJ hours updated successfully");
            getUserData();
            setIsEditing(false);
            props.close();
          });
      } catch (e) {
        handleToggle(false);
        console.log("ERROR:", e);
      }
    }
  };

  const reset = () => {
    const cloneDuration = cloneDeep(newLearner.monthlyHourMinutes);
    setNewLearner({
      ...newLearner,
      monthlyHourMinutesUpdated: cloneDuration,
    });
  };

  const isInPast = (date) => {
    // Split the date string into day, month, and year
    const [day, month, year] = date.split("/").map(Number);

    // Create a new Date object from the date string
    const inputDate = new Date(year, month - 1, day);

    // Get the current date
    const currentDate = new Date();

    // Compare the input date with the current date
    return inputDate < currentDate;
  };

  const editOTJHours = () => {
    if (data?.subscription.plan == "basic") {
      redirectToStripe();
    } else {
      setIsEditing(true);
    }
  };

  // To Redirect to Stripe For payment
  const redirectToStripe = async () => {
    let token = localStorage.getItem("7-star-training");
    let decodedToken = jwtDecode(token.replace("Bearer ", "")); // decode your token here

    try {
      handleToggle(true);
      await axios
        .post(
          "saas/billing",
          { id: decodedToken.id },
          {
            config: { handlerEnabled: true },
          }
        )
        .then((response) => {
          handleToggle(false);
          window.location.href = response.data.data.url;
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.close}
      PaperProps={{
        sx: { width: matches ? "60%" : "100%" },
      }}
    >
      <Box sx={{ m: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="drawerHeader"
        >
          <Typography id="modal-modal-title" variant="h5" component="h3">
            Off-The-Job Training
          </Typography>
          <CloseIcon
            className={classes.icons}
            title="Close"
            onClick={props.close}
          />
        </Grid>

        {newLearner && (
          <Fragment>
            <TableContainer
              sx={{
                maxHeight: 450,
                mt: 3,
              }}
            >
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "white",
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ fontSize: "18px", fontWeight: "600" }}>
                      Weeks
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "18px", fontWeight: "600" }}
                      align="center"
                    >
                      Hours
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "18px", fontWeight: "600" }}
                      align="center"
                    >
                      Minutes
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newLearner.monthlyHourMinutesUpdated?.map(
                    (duration, index) => (
                      <TableRow
                        key={duration?.weekName}
                        sx={{
                          backgroundColor:
                            isInPast(duration?.endDate) && isEditing
                              ? "grey.300"
                              : "initial",
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: { xs: "11px", sm: "14px" },
                            width: { xs: "10rem", sm: "13rem" },
                            padding: { xs: "8px", sm: "16px" },
                          }}
                        >
                          {duration?.weekName} - ({duration.startDate} -{" "}
                          {duration.endDate})
                        </TableCell>
                        <TableCell
                          sx={{
                            width: { xs: "4rem", sm: "6rem" },
                            padding: { xs: "8px", sm: "16px" },
                          }}
                          align="center"
                        >
                          {
                            <input
                              disabled={
                                !isEditing || isInPast(duration?.endDate)
                              }
                              style={{
                                borderColor:
                                  isInPast(duration?.endDate) || !isEditing
                                    ? "transparent"
                                    : "initial",
                              }}
                              type="number"
                              className="editHoursModalSignUp__table--input"
                              name="hours"
                              min="0"
                              value={
                                duration?.targetHours === ""
                                  ? ""
                                  : duration?.targetHours
                              }
                              onChange={(e) =>
                                handleDurationChangeHours(e, index)
                              }
                              inputMode="numeric"
                              pattern="[0-9]*"
                            />
                          }
                        </TableCell>
                        <TableCell
                          sx={{
                            width: { xs: "4rem", sm: "6rem" },
                            padding: { xs: "8px", sm: "16px" },
                            paddingRight: { xs: 0, sm: "16px" },
                          }}
                          align="center"
                        >
                          {
                            <input
                              disabled={
                                !isEditing || isInPast(duration?.endDate)
                              }
                              style={{
                                borderColor:
                                  isInPast(duration?.endDate) || !isEditing
                                    ? "transparent"
                                    : "initial",
                              }}
                              type="text" // Change to text for better control
                              className="editHoursModalSignUp__table--input"
                              name="minutes"
                              value={
                                duration?.targetMinutes === ""
                                  ? ""
                                  : duration?.targetMinutes
                              }
                              onChange={(e) =>
                                handleDurationChangeMinutes(e, index)
                              }
                              inputMode="numeric" // Ensures numeric keyboard on mobile devices
                              pattern="[0-9]*" // Restricts input to numeric values
                            />
                          }
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isEditing ? (
              <Fragment>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <span>
                    <p className="fontFixe">
                      Planned OTJ Hours:{" "}
                      {formatSecondsInOOOO(newLearner?.plannedTotalSeconds)}
                    </p>
                  </span>

                  <span>
                    <p className="fontFixe">
                      Forecast Hours: {formatSecondsInOOOO(changedSeconds)}
                    </p>
                  </span>

                  {/* <span>
                    <p className="fontFixe">
                      Above OR Under Planned Hours: {getTimeDifference()}
                    </p>
                  </span> */}
                  <IconButton
                    onClick={reset}
                    color="secondary"
                    aria-label="reset"
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Stack>
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 1 }}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setIsEditing(false);
                      reset();
                    }}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => calculateLearnerHoursHandler()}
                  >
                    Save
                  </Button>
                </Box>
              </Fragment>
            ) : (
              <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => editOTJHours()}
                  disabled={data?.subscription?.status === "inactive"}
                >
                  {data?.subscription.plan == "basic"
                    ? "To Edit, Upgrade Subscription"
                    : "Edit"}
                </Button>
                {/* <Tooltip
                  title={
                    data?.subscription.plan == "basic"
                      ? "To update OTJ Planned Hours, please upgrade your suscription level"
                      : ""
                  }
                  arrow
                >
                  <Box
                    component="span"
                    sx={{
                      cursor:
                        data?.subscription.plan == "basic" && "not-allowed",
                    }}
                  >
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => setIsEditing(true)}
                      disabled={data?.subscription.plan == "basic"}
                    >
                      Edit
                    </Button>
                  </Box>
                </Tooltip> */}
              </Box>
            )}
          </Fragment>
        )}
      </Box>
    </Drawer>
  );
}
