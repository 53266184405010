import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "../../redux/store";
import {
  getAllFaculties,
  setQuery,
  setAttribute,
} from "../../redux/slices/faculty";
import { useLocation, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { InputAdornment, TextField } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  flex: 1,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  // border: "1px solid #ccc",
  borderRadius: "4px",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      //   width: "12ch",
      width: "100%",
      "&:focus": {
        width: "100%",
      },
      "&:hover": {},
    },
  },
}));

const Toolbar = () => {
  const dispatch = useDispatch();
  const { attribute, query } = useSelector((state) => state.faculty);
  let { id } = useParams();
  let { pathname } = useLocation();
  pathname = pathname.split("/")[1];
  const handleChange = (event) => {
    dispatch(setAttribute(event.target.value));
    dispatch(getAllFaculties());
  };

  // const handleSearch = (event) => {
  //   dispatch(setQuery(event.target.value));
  //   dispatch(getAllFaculties());
  // };
  const handleSearch = React.useCallback(
    debounce(async (event) => {
      // dispatch(setQuery(event.target.value));
      dispatch(getAllFaculties());
    }, 1000),
    []
  );
  const clearSearch = (event) => {
    dispatch(setQuery(""));
    dispatch(getAllFaculties());
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginY: 2,
        marginX: 1,
        gap: 2,
      }}
    >
      {/* <Search fullWidth>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          type="text"
          onKeyDown={handleSearch}
        />
      </Search> */}
      <TextField
        placeholder="Search By Name and Email"
        fullWidth
        label="Search"
        variant="outlined"
        sx={{
          "& .MuiInputBase-input": {
            padding: "8.5px 0px",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {query !== "" && (
                <CancelIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    clearSearch();
                  }}
                />
              )}
            </InputAdornment>
          ),
        }}
        name="query"
        // onKeyDown={(e) => handleKeyDown(e)}
        value={query}
        onChange={(e) => {
          dispatch(setQuery(e.target.value));
          handleSearch(e);
        }}
      />
      {pathname !== "manager" && (
        <FormControl size="small" sx={{ minWidth: 140 }}>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={attribute}
            label="Role"
            onChange={handleChange}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"super admin"}>Super Admin</MenuItem>
            <MenuItem value={"admin"}>Admin</MenuItem>
            <MenuItem value={"coach"}>Coach</MenuItem>
            <MenuItem value={"mentor"}>Mentor</MenuItem>
            <MenuItem value={"manager"}>Manager</MenuItem>
            <MenuItem value={"visitor"}>Visitor</MenuItem>
            <MenuItem value={"trainer"}>Trainer</MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default Toolbar;
